import { useEffect, VFC, useMemo } from "react";

import * as Sentry from "@sentry/browser";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { Routes, Route, useLocation, useOutletContext, Outlet, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { Text, Grid, Image } from "theme-ui";

import { KeyValueMapping } from "src/components/destinations/key-value-mapping";
import dbtModelsImage from "src/components/extensions/assets/dbt-models.png";
import { GitCredentialsFields } from "src/components/extensions/git-credentials-fields";
import { Overview } from "src/components/extensions/overview";
import { GitFields } from "src/components/git/git-fields";
import { Page } from "src/components/layout";
import { SidebarForm } from "src/components/page";
import {
  DbtSyncConfig,
  GitCredentials,
  useCreateDbtSyncConfigMutation,
  useDbtSyncConfigsQuery,
  useGitCredentialsQuery,
  useUpdateDbtSyncConfigMutation,
} from "src/graphql";
import { Badge } from "src/ui/badge";
import { Row, Column, Container } from "src/ui/box";
import { Button } from "src/ui/button";
import { Card } from "src/ui/card";
import { Circle } from "src/ui/circle";
import { Field } from "src/ui/field";
import { Heading } from "src/ui/heading";
import { CheckCircleIcon, DBTIcon } from "src/ui/icons";
import { Input } from "src/ui/input";
import { Link } from "src/ui/link";
import { PageSpinner } from "src/ui/loading";
import { Message } from "src/ui/message";
import { Select } from "src/ui/select";
import { Tabs } from "src/ui/tabs";
import { Toggle } from "src/ui/toggle";
import { QueryType } from "src/utils/models";
import { useNavigate } from "src/utils/navigate";
import { useSources, UseSourcesResult } from "src/utils/sources";
import { formatDatetime } from "src/utils/time";

type Sources = UseSourcesResult["data"];

enum Tab {
  Overview = "Overview",
  Configuration = "Configuration",
}

const SUPPORTED_DBT_VERSIONS = [
  { label: "0.18", value: "0.18" },
  { label: "0.19", value: "0.19" },
  { label: "0.20", value: "0.20" },
  { label: "0.21", value: "0.21" },
  { label: "1.0", value: "1.0" },
  { label: "1.1", value: "1.1" },
];

const TABS = [Tab.Overview, Tab.Configuration];

export const DbtModels: VFC = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route
          element={
            <Overview
              description="Hightouch enables you to use dbt models stored in a git repository as source code for your Hightouch models. Once you
          connect the relevant git repo, Hightouch will then let you select which dbt Model you’d like to use during the
          modeling method step for each model setup. Setting up this requires you to git credentials under the Configuration
          tab. You will also need to tell us what the default schema to use is."
              icon={DBTIcon}
              image={dbtModelsImage}
              subtitle="Access dbt models stored in a git repo"
              title="dbt Models"
            />
          }
          path="/"
        />
        <Route element={<Configuration />} path="configuration" />
        <Route element={<SourceForm />} path="configuration/:sourceId" />
      </Route>
    </Routes>
  );
};

const Layout: VFC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { sourceId } = useParams();

  const path = location.pathname.split("/").pop();
  const tab = path === "configuration" ? Tab.Configuration : Tab.Overview;

  const { data: credentials, isLoading: credsLoading } = useGitCredentialsQuery(undefined, {
    select: (data) => data.git_credentials?.[0],
  });

  const { data: configs, isLoading: configsLoading } = useDbtSyncConfigsQuery(undefined, {
    refetchInterval: 3000,
    select: (data) => data.dbt_sync_config,
  });

  const { data: allSources, loading: sourcesLoading } = useSources({ limit: 1000 });

  const sources = allSources?.filter(
    ({ is_demo, definition }) => !is_demo && definition?.supportedQueries.includes(QueryType.DbtModel),
  );

  const source = sources?.find(({ id }) => id === Number(sourceId));

  const crumbs = source
    ? [
        { label: "Extensions", link: "/extensions" },
        { label: "dbt Models", link: "/extensions/dbt-models" },
        {
          label: source.name,
        },
      ]
    : [{ label: "Extensions", link: "/extensions" }, { label: "dbt Models" }];

  return (
    <Page crumbs={crumbs} size="medium">
      {!sourceId && (
        <Tabs
          setTab={(tab) => {
            if (tab === Tab.Overview) {
              navigate("/extensions/dbt-models");
            } else {
              navigate("configuration");
            }
          }}
          sx={{ mb: 10 }}
          tab={tab}
          tabs={TABS}
        />
      )}
      <Outlet context={{ credentials, configs, sources, source, loading: configsLoading || credsLoading || sourcesLoading }} />
    </Page>
  );
};

interface OutletContext {
  loading: boolean;
  credentials: GitCredentials;
  configs: Array<DbtSyncConfig>;
  sources: Sources;
  source: Sources[0] | undefined;
}

const Configuration: VFC = () => {
  const { loading, credentials, configs, sources } = useOutletContext<OutletContext>();

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <Row sx={{ flex: 1, justifyContent: "space-between" }}>
      <Container center={false} size="small">
        <Grid gap={12}>
          <Column>
            <Heading sx={{ mb: 6 }}>Credentials</Heading>
            <Grid gap={8}>
              <GitCredentialsFields credentials={credentials} isSetup={Boolean(credentials?.id)} />
            </Grid>
          </Column>
          <Column>
            <Heading sx={{ mb: 6 }}>dbt Connected Sources</Heading>
            <Grid gap={6}>
              {!sources?.length && (
                <Card size="small">
                  <Heading variant="h2">No sources</Heading>
                  <Text sx={{ my: 4, color: "base.5" }}>Add a compatible source to start using dbt Models</Text>
                  <Link sx={{ width: "max-content" }} to="/sources/new">
                    <Button>Add a source</Button>
                  </Link>
                </Card>
              )}
              {sources?.length > 0 && !credentials?.id && (
                <Text sx={{ fontSize: 2, color: "base.5" }}>Set up your git credentials before configuring dbt models</Text>
              )}
              {sources.map(({ id, name, definition }) => {
                const config = configs?.find((config) => String(config.connection_id) === String(id));

                const content = (
                  <Card
                    clickable={Boolean(credentials?.id)}
                    footer={
                      <Row sx={{ justifyContent: "flex-end", flex: 1 }}>
                        <Text sx={{ color: credentials?.id ? "primaries.8" : "base.4" }}>
                          {config ? "Manage" : "Connect to dbt"}
                        </Text>
                      </Row>
                    }
                    size="small"
                    sx={{ color: credentials?.id ? "black" : "base.5", bg: credentials?.id ? "white" : "base.1" }}
                  >
                    <Row sx={{ alignItems: "center", justifyContent: "space-between" }}>
                      <Row sx={{ alignItems: "center" }}>
                        <Image src={definition?.icon} sx={{ mr: 4, width: "24px" }} />
                        <Text sx={{ fontWeight: "bold", fontSize: 2 }}>{name}</Text>
                      </Row>

                      <CheckCircleIcon color={config ? "green" : "base.3"} />
                    </Row>
                    <Row
                      sx={{
                        justifyContent: "space-between",
                        alignItems: "center",
                        mt: 6,
                        display: config ? "flex" : "none",
                      }}
                    >
                      <Text sx={{ color: "base.7" }}>dbt to Hightouch</Text>
                      <Badge sx={{ alignItems: "center" }} variant="base">
                        <Circle color={config?.last_run_at ? (config?.error ? "red" : "green") : "gray"} radius="12px" />
                        <Text sx={{ ml: 2 }}>
                          {config?.last_run_at
                            ? formatDatetime(config?.last_run_at)
                            : config
                            ? "Waiting to be synced"
                            : "Not synced"}
                        </Text>
                      </Badge>
                    </Row>
                    <Row
                      sx={{
                        justifyContent: "space-between",
                        alignItems: "center",
                        mt: 3,
                        display: config ? "flex" : "none",
                      }}
                    >
                      <Text sx={{ color: "base.7" }}>dbt Schema</Text>
                      <Text sx={{ color: "base.7" }}>{config?.default_schema}</Text>
                    </Row>
                  </Card>
                );

                if (credentials?.id) {
                  return (
                    <Link key={id} to={`/extensions/dbt-models/configuration/${id}`}>
                      {content}
                    </Link>
                  );
                }

                return content;
              })}
            </Grid>
          </Column>
        </Grid>
      </Container>
      <SidebarForm docsUrl="models/dbt-models" name="dbt Models" />
    </Row>
  );
};

const SourceForm: VFC = () => {
  const { loading, configs, credentials } = useOutletContext<OutletContext>();
  const { addToast } = useToasts();
  const formMethods = useForm();
  const { sourceId } = useParams();

  const { mutateAsync: update } = useUpdateDbtSyncConfigMutation();
  const { mutateAsync: create } = useCreateDbtSyncConfigMutation();

  const config = useMemo(
    () => configs?.find(({ connection_id }) => String(connection_id) === String(sourceId)),
    [sourceId, configs],
  );

  const {
    register,
    reset,
    handleSubmit,
    formState: { isDirty, isSubmitting },
  } = formMethods;

  const submit = async (data) => {
    try {
      if (!config?.id) {
        await create({
          object: {
            ...data,
            git_credentials_id: credentials?.id,
          },
        });
      } else {
        await update({
          id: config.id,
          object: data,
        });
      }
      addToast("Configuration saved!", {
        appearance: "success",
      });
    } catch (e) {
      addToast("There was an error saving your configuration.", {
        appearance: "error",
      });
      Sentry.captureException(e);
    }
  };

  useEffect(() => {
    reset({
      repository: config?.repository ?? "",
      branch: config?.branch ?? "",
      path: config?.path ?? "",
      target: config?.target ?? "",
      selector: config?.selector ?? "",
      version: config?.version ?? "",
      default_schema: config?.default_schema ?? "",
      enabled: config?.enabled ?? false,
      connection_id: config?.connection_id,
      env_vars: config?.env_vars,
    });
  }, [config]);

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <FormProvider {...formMethods}>
      <Row sx={{ justifyContent: "space-between" }}>
        <Container center={false} size="small">
          <Grid gap={12}>
            <Column>
              <Heading sx={{ mb: 6 }}>Status</Heading>
              <Grid gap={4}>
                <Card size="small">
                  <Row sx={{ justifyContent: "space-between", alignItems: "center" }}>
                    <Row sx={{ alignItems: "center" }}>
                      <DBTIcon />
                      <Text sx={{ ml: 2 }}>dbt Models</Text>
                    </Row>
                    <Controller
                      name="enabled"
                      render={({ field }) => (
                        <Toggle label={field.value ? "Enabled" : "Disabled"} value={field.value} onChange={field.onChange} />
                      )}
                    />
                  </Row>
                </Card>
                <Card size="small">
                  <Row sx={{ justifyContent: "space-between", alignItems: "center" }}>
                    <Text>dbt to Hightouch</Text>
                    <Badge sx={{ alignItems: "center" }} variant="base">
                      <Circle color={config?.last_run_at ? (config?.error ? "red" : "green") : "gray"} radius="12px" />
                      <Text sx={{ ml: 2 }}>
                        {config?.last_run_at
                          ? formatDatetime(config?.last_run_at)
                          : config
                          ? "Waiting to be synced"
                          : "Not synced"}
                      </Text>
                    </Badge>
                  </Row>
                </Card>
              </Grid>
            </Column>

            <Column>
              <Heading sx={{ mb: 6 }}>Configuration</Heading>

              <Grid gap={8}>
                {config?.error && (
                  <Message sx={{ width: "100%" }} variant="error">
                    <Text as="pre" sx={{ wordBreak: "break-all", whiteSpace: "pre-wrap" }}>
                      {JSON.stringify(config?.error, null, 2)}
                    </Text>
                  </Message>
                )}

                <GitFields credentials={credentials} />

                <Field label="dbt Version">
                  <Controller
                    name="version"
                    render={({ field }) => (
                      <Select
                        options={SUPPORTED_DBT_VERSIONS}
                        placeholder="Select a version..."
                        value={field.value}
                        onChange={(selected) => {
                          field.onChange(selected?.value);
                        }}
                      />
                    )}
                  />
                </Field>
                <Field
                  description="The default schema is the schema where dbt would normally materialize your tables to unless otherwise specified in project file. Generally, this is something like public or production or dbt_production."
                  label="Default Schema"
                >
                  <Input {...register("default_schema")} />
                </Field>
                <Field
                  optional
                  description="By default, Hightouch will select all the models. Specify a dbt selector here such as tag:hightouch or *."
                  label="dbt Selector"
                >
                  <Input {...register("selector")} />
                </Field>
                <Field
                  optional
                  description="By default, Hightouch will look for the dbt_project.yml file in the directory. Specify a custom path here if there are multiple dbt_project.yml files."
                  label="Custom Path"
                >
                  <Input {...register("path")} />
                </Field>
                <Field
                  optional
                  description='By default, Hightouch uses the target name "prod" with the database credentials from the source. This is useful if you use a specific target.name variable in your dbt models.'
                  label="Custom Target"
                >
                  <Input {...register("target")} />
                </Field>
                <Field
                  optional
                  description="If set, Hightouch will run dbt commands with these environment variables."
                  label="Custom Environment Variables"
                >
                  <Controller
                    name="env_vars"
                    render={({ field }) => (
                      <KeyValueMapping
                        mapping={field.value}
                        setMapping={(map) => {
                          field.onChange(map);
                        }}
                      />
                    )}
                  />
                </Field>
              </Grid>
            </Column>
          </Grid>
        </Container>

        <SidebarForm
          buttons={
            <Button disabled={!isDirty} loading={isSubmitting} sx={{ width: "100%" }} onClick={handleSubmit(submit)}>
              Save
            </Button>
          }
          docsUrl="models/dbt-models"
          name="dbt Models"
        />
      </Row>
    </FormProvider>
  );
};
